import { React, useState } from "react";
import {
  Images,
  Upload,
  UserSearchIcon,
  ChevronLeft,
  Camera,
  ChevronRight,
  Plus,
  ArrowRight,
  Image,
  Sparkles,
} from "lucide-react";
import { DownloadButton } from "../Utils/DownloadButton.jsx";
import FileUploader from "./FileUploader.jsx";
import { useSelector } from "react-redux";

const ImageProcessor = ({
  title,
  images,
  modelImages,
  uploadTitle,
  onFaceSelect,
  onModelSelect,
  onFaceUpload,
  onModelUpload,
  onRun,
  selectedFace,
  selectedModel,
  appliedFilter,
  isRunning,
  isDownloadReady,
  Header,
  type_run,
}) => {
  const [showAllImages, setShowAllImages] = useState(false);
  const [showAllModelImages, setShowAllModelImages] = useState(false);
  const [fullscreenImage, setFullscreenImage] = useState(null);
  const [faceImage, setFaceImage] = useState({});
  const [modelImage, setModelImage] = useState({});
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [currentModelImageIndex, setCurrentModelImageIndex] = useState(0);

  const isSidebarOpen = useSelector((state) => state.studio.sidebar.isOpen);

  const handleFaceImageSelect = (img) => {
    onFaceSelect(img);
    setFaceImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllImages(false);
  };

  const handleModelImageSelect = (img) => {
    onModelSelect(img);
    setModelImage({
      newImage: img,
      newImageUrl: img,
    });
    setShowAllModelImages(false);
  };

  const handleShowMoreFaces = () => {
    setShowAllImages(true);
  };

  const handleShowMoreModels = () => {
    setShowAllModelImages(true);
  };

  const handleFaceImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setFaceImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onFaceSelect(newImageUrl);
    }
  };

  const handleModelImageUpload = (event) => {
    if (event.target.files && event.target.files[0]) {
      const newImage = event.target.files[0];
      const newImageUrl = URL.createObjectURL(newImage);

      setModelImage({
        newImage: newImage,
        newImageUrl: newImageUrl,
      });
      onModelSelect(newImageUrl);
    }
  };

  const openFullscreenImage = (imageUrl) => {
    setFullscreenImage(imageUrl);
  };

  const closeFullscreenImage = () => {
    setFullscreenImage(null);
  };

  const handleFaceReset = () => {
    setFaceImage({});
  };

  const handleModelReset = () => {
    setModelImage({});
  };

  return (
    <div
      className={`${isSidebarOpen ? "ml-48" : ""} bg-[#222226] transition-all duration-200 px-8 p-4 rounded-xl`}
    >
      <h1 className="text-3xl mb-2 text-center text-[#1D3A72]">{Header}</h1>
      <div className="flex flex-col lg:flex-row items-center gap-4">
        {/* Left side - Face Images */}
        <div className="w-full lg:w-1/3 rounded-lg p-4">
          <h2 className="text-lg text-white text-center mb-4">{title}</h2>
          <div className="aspect-square w-[300px] mx-auto mb-4">
            {faceImage.newImageUrl ? (
              <img
                src={faceImage.newImageUrl}
                alt="preview"
                className="w-full h-full object-contain rounded-lg"
              />
            ) : (
              <FileUploader handleImageUpload={handleFaceImageUpload} />
            )}
          </div>
          <div className="flex justify-center mb-4">
            <div className="flex items-center gap-2 overflow-x-auto">
              {images
                .slice(currentImageIndex, currentImageIndex + 3)
                .map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                      selectedFace === img ? "border-2 border-yellow-400" : ""
                    }`}
                    alt={`Filter ${currentImageIndex + index + 1}`}
                    onClick={() => handleFaceImageSelect(img)}
                  />
                ))}
              <button
                className="w-20 h-20 p-2 border text-gray-400 text-sm rounded-lg hover:bg-[#282828] transition-colors flex items-center justify-center"
                onClick={handleShowMoreFaces}
              >
                <span className="text-sm">Show More</span>
              </button>
            </div>
          </div>
          {faceImage.newImageUrl && (
            <div className="flex justify-center">
              <button
                onClick={handleFaceReset}
                className="bg-red-500 px-12 py-2 rounded-lg text-white uppercase"
              >
                Reset
              </button>
            </div>
          )}
        </div>

        {/* Center Icons */}
        <div className="flex flex-col items-center justify-center">
          <Plus className="text-gray-500 w-12 h-12 mb-2" />
        </div>

        <div className="w-full lg:w-2/3 rounded-lg">
          <div className="flex flex-col md:flex-row gap-4">
            {/* Model Image Upload Section */}
            <div className="w-full md:w-1/2 rounded-lg p-4">
              <h2 className="text-lg text-white text-center mb-4">
                {uploadTitle}
              </h2>
              <div className="aspect-square w-[300px] mx-auto mb-4">
                {modelImage.newImageUrl ? (
                  <img
                    src={modelImage.newImageUrl}
                    alt="preview"
                    className="w-full h-full object-contain rounded-lg"
                  />
                ) : (
                  <FileUploader handleImageUpload={handleModelImageUpload} />
                )}
              </div>
              <div className="flex justify-center mb-4">
                <div className="flex items-center gap-2 overflow-x-auto">
                  {modelImages
                    .slice(currentModelImageIndex, currentModelImageIndex + 3)
                    .map((img, index) => (
                      <img
                        key={index}
                        src={img}
                        className={`w-20 h-20 object-cover rounded-lg cursor-pointer transition-all duration-300 ${
                          selectedModel === img
                            ? "border-2 border-yellow-400"
                            : ""
                        }`}
                        alt={`Model ${currentModelImageIndex + index + 1}`}
                        onClick={() => handleModelImageSelect(img)}
                      />
                    ))}
                  <button
                    className="w-20 h-20 p-2 border text-gray-400 text-sm rounded-lg hover:bg-[#282828] transition-colors flex items-center justify-center"
                    onClick={handleShowMoreModels}
                  >
                    <span className="text-sm">Show More</span>
                  </button>
                </div>
              </div>
              {modelImage.newImageUrl && (
                <div className="flex justify-center">
                  <button
                    onClick={handleModelReset}
                    className="bg-red-500 px-12 py-2 rounded-lg text-white uppercase"
                  >
                    Reset
                  </button>
                </div>
              )}
            </div>

            <div className="flex items-center">
              <ArrowRight className="text-gray-500 w-12 h-12" />
            </div>

            {/* Processed Image Section */}
            <div className="w-full md:w-1/2 relative rounded-lg py-4">
              <h2 className="text-lg text-white text-center mb-4">
                Output Image
              </h2>
              <div className="aspect-square w-full  mb-4">
                {isRunning ? (
                  <div className="w-full h-full">
                    <div className="w-full h-full bg-[#333537] rounded-lg flex items-center justify-center">
                      <h1 className="text-xl text-yellow-400">
                        Generation in Process
                      </h1>
                    </div>
                    <div className="loader"></div>
                  </div>
                ) : appliedFilter ? (
                  <img
                    src={appliedFilter}
                    alt="filtered"
                    className="w-full h-full object-contain rounded-lg cursor-pointer"
                    onClick={() => openFullscreenImage(appliedFilter)}
                  />
                ) : (
                  <div className=" bg-[#333537] p-4 h-full flex flex-col items-center justify-center rounded-lg">
                    <Image size={200} className="text-gray-300" />
                    <h1 className="text-sm text-gray-500">
                      Nothing to see here yet!
                    </h1>
                  </div>
                )}
                <div className="mt-8 flex justify-center gap-8 absolute w-full">
                  <button
                    onClick={onRun}
                    disabled={
                      !selectedFace.blobImage ||
                      !selectedModel.blobImage ||
                      isRunning
                    }
                    className="  text-black bg-[#8AB4F8] py-3 px-8 rounded-md cursor-pointer hover:bg-[#5286d8] transition-colors flex gap-2 items-center justify-center"
                  >
                    <Sparkles size={16} />
                    <span className="text-sm">
                      {" "}
                      {isRunning ? "Generating..." : "Generate"}
                    </span>
                  </button>

                  <div className=" ">
                    <DownloadButton imageUrl={appliedFilter} />
                  </div>
                </div>
              </div>

              {/* {isDownloadReady && (
               
              )} */}
            </div>
          </div>
        </div>

        {/* Fullscreen Image Modal */}
        {fullscreenImage && (
          <div
            onClick={closeFullscreenImage}
            className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50"
          >
            <div className="relative w-full h-full max-w-4xl max-h-4xl">
              <img
                src={fullscreenImage}
                alt="fullscreen"
                className="w-full h-full object-contain"
              />
            </div>
          </div>
        )}

        {/* Pop-up for all face images */}
        {showAllImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
                onClick={() => setShowAllImages(false)}
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-6">Choose a Face Image</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedFace === img
                        ? "ring-4 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Filter ${index + 1}`}
                    onClick={() => handleFaceImageSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}

        {/* Pop-up for all model images */}
        {showAllModelImages && (
          <div className="fixed inset-0 bg-black bg-opacity-75 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-8 w-11/12 max-w-5xl max-h-[90vh] overflow-y-auto relative">
              <button
                className="absolute top-4 right-4 text-3xl font-bold text-gray-600 hover:text-gray-800"
                onClick={() => setShowAllModelImages(false)}
              >
                ×
              </button>
              <h3 className="text-2xl font-bold mb-6">Choose a Model Image</h3>
              <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                {modelImages.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    className={`w-full aspect-square object-cover rounded-lg cursor-pointer ${
                      selectedModel === img
                        ? "ring-4 ring-purple-600"
                        : "ring-1 ring-gray-300 hover:ring-purple-400"
                    }`}
                    alt={`Model ${index + 1}`}
                    onClick={() => handleModelImageSelect(img)}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Run Button */}
      {/* <div className="flex items-center justify-center mt-2">
        <button
          className={`w-full uppercase max-w-[400px] px-4 py-2 rounded-lg flex items-center justify-center text-lg font-semibold ${
            selectedFace.blobImage && selectedModel.blobImage
              ? "bg-[#E4AA0E] text-blue-900 hover:bg-yellow-500"
              : "bg-gray-300 text-gray-600 cursor-not-allowed"
          } transition-colors`}
          onClick={onRun}
          disabled={!selectedFace.blobImage || !selectedModel.blobImage || isRunning}
        >
          {isRunning ? "Processing..." : type_run}
        </button>
      </div> */}
    </div>
  );
};

export default ImageProcessor;
