import React, { Fragment } from "react";
import BannerCard from "./BannerCard";
import HeadImage from "../../assets/images/Head.svg";
import ExerciseImage from "../../assets/images/Exercise.svg";
import Catlogue from "../../assets/images/catalogue.png";
import headingbg from "../../assets/images/headingbg.svg";

const Banner = () => {
  let mainheading = "Welcome to Nexusware";
  const description = "Achieve more with AI Start your transformation now";

  const cardsData = [
    {
      id: 1,
      imageSrc: HeadImage,
      title: "Quick Swap",
      description:
        "AI tools for Face Swap, Background Editing, Apparel Swap, and SEO-friendly Cloth Description creation, boosting efficiency.",
      link: "/dashboard/app",
    },
    {
      id: 2,
      imageSrc: ExerciseImage,
      title: "Image Engineering",
      description:
        "Advanced tools for cropping, resizing, and aspect ratio adjustments, ensuring platform-optimized, high-quality visuals.",
      link: "#/",
    },
    {
      id: 3,
      imageSrc: Catlogue,
      title: "Cataloguer",
      description:
        "Transforms clothing images into AI-generated model visuals, showcasing diverse styles and poses, eliminating traditional photoshoots.",
      link: "/dashboard/Catalogue",
    },
    {
      id: 4,
      imageSrc: HeadImage,
      title: "Image to Video",
      description:
        "Creates dynamic videos from images, showcasing apparel in motion for an engaging, interactive shopping experience.",
      link: "/dashboard/image-to-video",
    },
  ];

  return (
    <Fragment>
      <div
        className="mt-5 pb-5 bg-white min-h-screen"
        id="bannerSection"
        style={{ position: "relative" }}
      >
        <div className="container">
          <div className="row">
            <div
              className="text-center text-white mt-5"
              style={{ position: "relative" }}
            >
              <h1 className="main_heading">{mainheading}</h1>
              <img src={headingbg} alt="no_img" className="heading_bg" />
              <p className="main_description">{description}</p>
            </div>
            <div className="row row-cols-1 row-cols-md-2 g-4">
              {cardsData.map((card, index) => (
                <BannerCard
                  key={index}
                  id={card.id}
                  imageSrc={card.imageSrc}
                  title={card.title}
                  description={card.description}
                  link={card.link}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Banner;
