import { axiosInstance } from "./axiosConfig";

export const uploadImage = async (
  imageFile,
  category,
  isPrivate,
  source = "NON_AI",
) => {
  const formData = new FormData();
  formData.append("file", imageFile);
  formData.append("file_type", category);
  formData.append("private", isPrivate);
  formData.append("source", source);

  try {
    const response = await axiosInstance.post(`/files/images/`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getCatalogueImages = async () => {
  return [
    {
      file_type: image_type,
    },
  ];
};

export const getStaticImages = async (image_type, source_type = null) => {
  try {
    let params = {};
    if (!image_type && !source_type) {
      // throw new Error("At least one of image_type or source_type is required");
    } else if (image_type && !source_type) {
      params = { file_type: image_type };
    } else if (!image_type && source_type) {
      params = { source: source_type };
    }
    const response = await axiosInstance.get(`/files/images/`, {
      params: params,
    });
    return response;
  } catch (error) {
    throw error.response ? error.response.data : new Error("Network Error");
  }
};

export const getFaceImages = async () => {
  const images = await getStaticImages("FACE");
  return images.data;
};

export const getFullModelImages = async () => {
  const images = await getStaticImages("MODEL_FULL");
  return images.data;
};

export const getUpperHalfModelImages = async () => {
  const images = await getStaticImages("MODEL_UPPER_HALF");
  return images.data;
};

export const get_lower_half_model_images = async () => {
  const images = await getStaticImages("MODEL_LOWER_HALF");
  return images.data;
};

export const getBackdropImages = async () => {
  const images = await getStaticImages("BACKDROP");
  return images.data;
};

export const getClothImages = async () => {
  const images = await getStaticImages("CLOTH");
  return images.data;
};
