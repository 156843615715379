import React, { useState } from "react";
import { UploadCloud } from "lucide-react";

const FileUploader = ({ handleImageUpload }) => {
  const [isDragOver, setIsDragOver] = useState(false);
  const [preview, setPreview] = useState(null);

  // Drag-and-Drop Handlers
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragOver(false);

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleImageUpload(e);
      const file = files[0];
      const reader = new FileReader();
      reader.onload = (e) => setPreview(e.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (e) => {
    const files = e.target.files;
    if (!files || files.length === 0) {
      console.error("No files selected");
      return;
    }

    const file = files[0];
    const reader = new FileReader();
    reader.onload = (e) => setPreview(e.target.result);
    reader.readAsDataURL(file);
    handleImageUpload(e);
  };

  return (
    <div className="flex flex-col items-center">
      {/* Drag-and-Drop Area */}
      <div
        className={`flex bg-[#333537] rounded-lg p-2 h-72 justify-center items-center`}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        <div
          className={` h-[260px] flex flex-col items-center ${
            isDragOver ? "border-purple-600" : ""
          } rounded-lg p-4 w-full max-w-md`}
        >
          <div className="flex items-center justify-center mb-4">
            <UploadCloud
              size={56}
              className={`${isDragOver ? "text-purple-600" : "text-white"}`}
            />
          </div>
          <label className="relative inline-block">
            <input
              type="file"
              accept="image/*"
              onChange={handleFileChange}
              className="absolute inset-0 opacity-0 cursor-pointer w-full h-full"
            />
            <span className=" text-white border border-[#8AB4F8] py-2 px-8 rounded-md cursor-pointer hover:bg-purple-700 transition-colors flex items-center justify-center">
              <span className="text-sm"> Upload</span>
            </span>
          </label>
          <h1 className="text-sm text-center font-normal text-gray-400 mt-6 mb-2">
            Select a file or drag and drop here
          </h1>
          <p className="text-gray-400 text-center text-xs mb-4">
            JPG, PNG, or WEBP files are supported
          </p>
        </div>
      </div>
    </div>
  );
};

export default FileUploader;
